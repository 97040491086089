* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    background-color: $primary;
    font-display: swap;
    scroll-behavior: smooth;
}

ul {
    padding-left: 0;
    list-style: none;
}

img {
    width: 100%;
}

a {
    text-decoration: none;
}

