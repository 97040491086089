.CookieConsent {
    background: rgba(8, 9, 8, 0.05) !important;
    backdrop-filter: blur(50px);

    align-items: center;

    padding: 16px 32px;

    font-family: $fontSecondary;
    font-size: $size-4;
    color: $white;

    div:first-child {
        margin: 0 !important;
    }

    button {
        font-family: $fontPrimary;
        color: $white !important;
        background: $secondary !important;
        border: 1.5px solid $secondary !important;
        border-radius: 2px !important;
        padding: 11px 22px;
        font-style: normal;
        font-weight: $weight-4;
        font-size: $size-2-custom;
        line-height: 150%;
        align-items: center;
        display: flex;
        width: fit-content;
        margin: 0 !important;
        transition: background .3s ease-in-out;

        &:hover {
            background: transparent !important;
            transition: background .3s ease-in-out;
        }
    }
}