// Colors
$primary: #0d0d0d;
$secondary: #AB9E56;
$tertiary: #716D66;

// Light color

// Custom colors 
$white: #E6E4DD;
$black: #080908;

// Backgrounds
$bg-secondary: #E6E4DD;


// Fonts
$fontPrimary: 'freightdispcmp-pro', serif;
$fontSecondary: 'Poppins', serif;


// Screen Size
$max-width-s: 70vw;
$max-width-m: 80vw;
$max-width-l: 85vw;
$max-width-lg: 90vw;
$max-width-xg: 95vw;


// Resolution
$desktop-1: 1280px;
$desktop-2: 1366px;
$desktop-3: 1666px;
$desktop-4: 1920px;
$tablet: 1024px;
$mobile: 786px;


// Font Size Custom
$size-1-custom: 15px;   
$size-2-custom: 20px;   
$size-3-custom: 30px;   
$size-4-custom: 48px;   

// Font Size
$size-1: 10px;
$size-2: 11px;
$size-3: 12px;
$size-4: 13px;
$size-5: 16px;
$size-6: 18px;
$size-7: 22px;
$size-8: 24px;
$size-9: 28px;
$size-10: 32px;
$size-11: 35px;
$size-12: 40px;
$size-13: 50px;
$size-14: 60px;
$size-15: 80px;



// Weight
$weight-1: 400;
$weight-2: 500;
$weight-3: 600;
$weight-4: 700;
$weight-5: 800;
$weight-6: 900;


// Margins 
$margin-1: 10px;
$margin-2: 20px;
$margin-3: 30px;
$margin-4: 40px;
$margin-5: 50px;
$margin-6: 80px;
$margin-7: 100px;
$margin-8: 150px;

// Paddings
$padding-1: 10px;
$padding-2: 20px;
$padding-3: 30px;
$padding-4: 40px;
$padding-5: 50px;
$padding-6: 80px;
$padding-7: 100px;
$padding-8: 150px;


$radius-s: 5px;
$radius-m: 10px;
$radius-l: 25px;
$radius-lg: 50px;

