.header {
    background: rgba(8, 9, 8, 0.05);
    backdrop-filter: blur(50px);
    position: sticky;
    top: 0;
    z-index: 1;
    .header__container {
        margin: auto;
        width: $max-width-xg;
        .header__container-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 98px;
            @media (max-width: $tablet) {
                height: 83px;
            }

            .logo {
                img {
                    @media (max-width: $tablet) {
                        height: 45px;
                    }
                }
            }
            
            .contact {
                font-family: $fontPrimary;
                font-size: $size-2-custom;
                font-style: normal;
                font-weight: $weight-2;
                line-height: 150%;
                letter-spacing: 1px;
                a {
                    color: $white;
                    display: flex;
                    align-items: center;
                    transition: color .3s ease-in-out;

                    &:hover {
                        color: $secondary;
                        transition: color .3s ease-in-out;
                    }

                    &::after {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_23_83' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_23_83)'%3E%3Cpath d='M4.50004 16.6667L3.33337 15.5L13 5.83332H7.50004V4.16666H15.8334V12.5H14.1667V6.99999L4.50004 16.6667Z' fill='%23AB9E56'/%3E%3C/g%3E%3C/svg%3E%0A");
                        margin-left: $margin-1;
                    }
                }
            }

            .nav {
                .nav__list {
                    display: flex;
                    list-style: none;
                    
                    .nav__list-link-desktop {
                        font-family: $fontPrimary;
                        margin: 0 $margin-2;
                        font-size: $size-6;
                        font-style: normal;
                        font-weight: $weight-2;
                        line-height: 150%;
                        letter-spacing: 1px;
                        a {
                            color: $white;
                            transition: color .3s ease-in-out;

                            &:hover {
                                color: $secondary;
                                transition: color .3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}