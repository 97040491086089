.banner {
    .banner__left {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0 50px;
        align-items: center;
        height: 950px;
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            height: 700px;
        }
        .banner__content-2 {
            background-image: url(../../../assets/images/thumbnail-video.webp);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: -webkit-fill-available;
            position: relative;
            @media (max-width: $tablet) {
                height: 306px;
                margin: auto;
                width: 90%;
                margin-top: 24px;
            }

            .banner__content-2-image {
                max-width: 120px;
                max-height: 120px;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 40%;
                left: 45%;
                cursor: pointer;

                @media (max-width: $tablet){
                    max-width: 80px;
                    max-height: 80px;
                    top: 38%;
                    left: 40%;
                }
            }
        }
    }

    .banner__content-1 {
        padding-left: $padding-3;
        @media (max-width: $tablet) {
            margin: auto;
            width: $max-width-lg;
            padding-left: 0;
        }

        .title {
            font-family: $fontPrimary;
            font-style: normal;
            font-weight: $weight-3;
            font-size: 64px;
            line-height: 90%;
            color: $white;
            margin-bottom: $margin-3;
            @media (max-width: $tablet) {
                font-size: $size-4-custom;
                text-align: center;
            }

            .span-title {
                color: 	$secondary;
            }
        }

        .description {
            color: $white;
            margin-bottom: 51px;
            font-size: $size-1-custom;
            font-family: $fontSecondary;
            margin-bottom: $margin-3;
            @media (max-width: $tablet) {
                text-align: center;
            }
        }

        .banner_link {
            font-family: $fontPrimary;
            @media (max-width: $tablet) {
                display: flex;
                justify-content: center;
            }

            a {
                color: $white;
                background: $secondary;
                border: 1.5px solid $secondary;
                border-radius: 2px;
                padding: 18px 22px;
                font-style: normal;
                font-weight: $weight-3;
                font-size: $size-2-custom;
                line-height: 150%;
                align-items: center;
                display: flex;
                width: fit-content;
                transition: background .3s ease-in-out;

                &:hover {
                    background: transparent;
                    transition: background .3s ease-in-out;
                }

                &::before {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_23_92' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_23_92)'%3E%3Cpath d='M16.525 17.4C14.7333 17.4 12.9869 17.0005 11.2858 16.2017C9.58416 15.4033 8.07722 14.3478 6.76499 13.035C5.45222 11.7228 4.39666 10.2158 3.59833 8.51416C2.79944 6.81305 2.39999 5.06666 2.39999 3.27499C2.39999 3.02499 2.48333 2.81666 2.64999 2.64999C2.81666 2.48333 3.02499 2.39999 3.27499 2.39999H6.64999C6.84444 2.39999 7.01805 2.46249 7.17083 2.58749C7.32361 2.71249 7.41388 2.87222 7.44166 3.06666L7.98333 5.98333C8.0111 6.17777 8.00777 6.35472 7.97333 6.51416C7.93833 6.67416 7.85833 6.81666 7.73333 6.94166L5.73333 8.98333C6.31666 9.98333 7.04583 10.9208 7.92083 11.7958C8.79583 12.6708 9.76111 13.4278 10.8167 14.0667L12.775 12.1083C12.9 11.9833 13.0633 11.8894 13.265 11.8267C13.4661 11.7644 13.6639 11.7472 13.8583 11.775L16.7333 12.3583C16.9278 12.4 17.0875 12.4936 17.2125 12.6392C17.3375 12.7853 17.4 12.9555 17.4 13.15V16.525C17.4 16.775 17.3167 16.9833 17.15 17.15C16.9833 17.3167 16.775 17.4 16.525 17.4Z' fill='%23E6E4DD'/%3E%3C/g%3E%3C/svg%3E%0A");
                    margin-right: $margin-1;
                }
            }
        }
    }
}