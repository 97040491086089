.questions {
    padding-top: $padding-5;
    .questions__col-left {
        .title {
            font-family: $fontPrimary;
            font-style: normal;
            font-weight: $weight-3;
            font-size: $size-4-custom;
            line-height: 105%;
            text-align: center;
            color: $white;
        }
    }

    .questions__col-right {
        width: $max-width-s;
        margin: auto;

        @media (max-width: $tablet) {
            width: $max-width-lg;
        }
        .question-item {
            border-bottom: solid 1px $secondary;
            .ask {
                font-family: $fontPrimary;
                font-style: normal;
                font-weight: $weight-3;
                font-size: $size-8;
                line-height: 105%;
                font-feature-settings: 'liga' off;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
            
                @media (max-width: $tablet) {
                    align-items: unset;
                    padding-right: 24px;
                }

                &.open {
                    color: $secondary;
                    transition: all .3s ease-in-out;
                    &::after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 21px;
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_59_211' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='21'%3E%3Crect y='0.5' width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_59_211)'%3E%3Cpath d='M10 13.3127L5 8.31266L6.16667 7.146L10 10.9793L13.8333 7.146L15 8.31266L10 13.3127Z' fill='%23E6E4DD'/%3E%3C/g%3E%3C/svg%3E%0A");
                        transform: rotate(180deg);
                        transition: all .3s ease-in-out;
                        @media (max-width: $tablet) {
                            width: 24px;
                            position: absolute;
                            right: 15px;
                        }
                    }
                }

                &.close {
                    transition: all .3s ease-in-out;
                    &::after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 21px;
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_59_211' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='21'%3E%3Crect y='0.5' width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_59_211)'%3E%3Cpath d='M10 13.3127L5 8.31266L6.16667 7.146L10 10.9793L13.8333 7.146L15 8.31266L10 13.3127Z' fill='%23E6E4DD'/%3E%3C/g%3E%3C/svg%3E%0A"); 
                        transition: all .3s ease-in-out;                       
                        @media (max-width: $tablet) {
                            width: 24px;
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }
            .answer {
                font-family: $fontSecondary;
                font-style: normal;
                font-weight: $weight-1;
                font-size: $size-4;
                line-height: 105%;
                padding-bottom: 16px;
                font-feature-settings: 'liga' off;
                color: $white;
                letter-spacing: 0.05em;
            }
        }
    }
}