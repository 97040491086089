.info {
    background-color: $bg-secondary;
    margin-top: -128px;
    @media (max-width: $tablet){
        border-bottom: 8px solid #AB9E56;
        margin-top: inherit;
    }
    .info__container {
        padding-top: 190px;
        padding-bottom: 100px;
        margin: auto;
        width: $max-width-s;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 0 30px;

        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            width: $max-width-lg;
            padding-top: 90px;
        }

        .info__content-1 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;

            @media (max-width: $tablet) {
                order: 1;
            }

            .info__image-3 {
                @media (max-width: $mobile){ margin-top: -47px;}
                @media (min-width: $desktop-1) and (max-width: $desktop-2){ margin-top: -60px; }
                @media (min-width: $desktop-2) and (max-width: $desktop-3){ margin-top: -64px; }
                @media (min-width: $desktop-3){ margin-top: -81px; }
                @media (min-width: $desktop-4){ margin-top: -94px; }
                
            }
        }

        .info__content-2 {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            .title {
                font-family: $fontPrimary;
                font-style: normal;
                font-weight: $weight-3;
                font-size: $size-4-custom;
                line-height: 105%;
                color: #080908;
                margin-bottom: 12px;
                @media (max-width: $tablet) {
                    font-size: $size-11;
                    text-align: center;
                    margin-top: $margin-6;
                }
            }

            .description {
                font-family: $fontSecondary;
                font-style: normal;
                font-weight: $weight-1;
                font-size: $size-1-custom;
                line-height: 150%;
                letter-spacing: 0.05em;
                font-feature-settings: 'liga' off;
                color: $tertiary;
                @media (max-width: $tablet) {
                    text-align: center;
                    margin-bottom: $margin-3;
                }
            }
        }
    }
}