.footer__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .footer__copyright {
            font-family: $fontSecondary;
            font-style: normal;
            font-weight: $weight-1;
            font-size: 12.5px;
            line-height: 180%;
            text-align: center;
            letter-spacing: 0.05em;
            font-feature-settings: 'liga' off;
            color: $white;
            margin-bottom: 0;

            @media (max-width: $tablet) {
                width: $max-width-s;
            }
        }

        .footer__terms {
            font-family: $fontSecondary;
            font-style: normal;
            font-weight: $weight-6;
            font-size: 12.5px;
            line-height: 180%;
            text-align: center;
            letter-spacing: 0.05em;
            font-feature-settings: 'liga' off;
            color: $secondary;
            margin-top: 5px;
        }

        .footer__link a {
            margin-bottom: $margin-5;
            font-family: $fontPrimary;
            color: $white;
            background: $secondary;
            border: 1.5px solid $secondary;
            border-radius: 2px;
            padding: 18px 22px;
            font-style: normal;
            font-weight: $weight-3;
            font-size: $size-2-custom;
            line-height: 150%;
            align-items: center;
            display: flex;
            width: fit-content;
            transition: background .3s ease-in-out;

            &:hover {
                background: transparent;
                transition: background .3s ease-in-out;
            }

            &::before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_23_92' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='20' height='20'%3E%3Crect width='20' height='20' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_23_92)'%3E%3Cpath d='M16.525 17.4C14.7333 17.4 12.9869 17.0005 11.2858 16.2017C9.58416 15.4033 8.07722 14.3478 6.76499 13.035C5.45222 11.7228 4.39666 10.2158 3.59833 8.51416C2.79944 6.81305 2.39999 5.06666 2.39999 3.27499C2.39999 3.02499 2.48333 2.81666 2.64999 2.64999C2.81666 2.48333 3.02499 2.39999 3.27499 2.39999H6.64999C6.84444 2.39999 7.01805 2.46249 7.17083 2.58749C7.32361 2.71249 7.41388 2.87222 7.44166 3.06666L7.98333 5.98333C8.0111 6.17777 8.00777 6.35472 7.97333 6.51416C7.93833 6.67416 7.85833 6.81666 7.73333 6.94166L5.73333 8.98333C6.31666 9.98333 7.04583 10.9208 7.92083 11.7958C8.79583 12.6708 9.76111 13.4278 10.8167 14.0667L12.775 12.1083C12.9 11.9833 13.0633 11.8894 13.265 11.8267C13.4661 11.7644 13.6639 11.7472 13.8583 11.775L16.7333 12.3583C16.9278 12.4 17.0875 12.4936 17.2125 12.6392C17.3375 12.7853 17.4 12.9555 17.4 13.15V16.525C17.4 16.775 17.3167 16.9833 17.15 17.15C16.9833 17.3167 16.775 17.4 16.525 17.4Z' fill='%23E6E4DD'/%3E%3C/g%3E%3C/svg%3E%0A");
                margin-right: $margin-1;
            }
        }
    }
}