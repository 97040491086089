.material {
    background-image: url('../../../assets/images/material-bg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  
    @media (max-width: $tablet) {
        padding-bottom: $margin-6;
    }
    
    .material__container {
        height: 716px;
        width: $max-width-lg;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            height: 616px;
        }

        .material__left-col {
            padding-left: $padding-3;
            @media (max-width: $tablet) {
                padding-left: 0;
                margin-top: $margin-3;
                margin-bottom: 0;
            }
            .title {
                font-family: $fontPrimary;
                font-style: normal;
                font-weight: $weight-3;
                font-size: $size-4-custom;
                line-height: 105%;
                color: $black;
                user-select: none;
                @media (max-width: $tablet) {
                    font-size: $size-11;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }
    
        .material__right-col {
            display: grid;
            grid-gap: 0 25px;
            grid-auto-columns: 35%;
            grid-auto-flow: column;
            overflow-x: auto;
            @media (max-width: $tablet) {
                grid-auto-columns: 80%;
                overflow-y: hidden;
                grid-gap: 9px;
            }
    
            &::-webkit-scrollbar{
                height: 4px;
                width: 4px;
                background: gray;
                padding-top: 10px;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1; 
            }
             
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888; 
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }
            
            &::-webkit-scrollbar-thumb:horizontal{
                background: $secondary;
                border-radius: 10px;
            }
    
            .material__item {
                background: rgba(180, 175, 163, 0.4);
                border-top: 5px solid #AB9E56;
                border-radius: 2px;
                padding: $padding-6 $padding-3;
                margin-bottom: $margin-2;
    
                @media (max-width: $tablet) {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                @media (max-width: $desktop-2){
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .title {
                    font-family: $fontPrimary;
                    font-style: normal;
                    font-weight: $weight-3;
                    font-size: $size-4-custom;
                    line-height: 105%;
                    color: $black;
                    user-select: none;
    
                    @media (max-width: $tablet) {
                        font-size: $size-11;
                        margin-bottom: $margin-1;
                        margin-top: $margin-4;
                    }
                }
    
                .label {
                    font-family: $fontSecondary;
                    font-style: normal;
                    font-weight: $weight-1;
                    font-size: $size-1-custom;
                    line-height: 150%;
                    letter-spacing: 0.05em;
                    font-feature-settings: 'liga' off;
                    color: $tertiary;
                    user-select: none;
                }
            }
        }
    }

    
}
