

// Define the background before and width and height the icon image
@mixin icon($width, $height){
    content: "";
    display: block;
    width: $width;
    height: $height;
    background-repeat: no-repeat;
    cursor: pointer;
}

@mixin investmentIcons($icon){
    content: "";
    display: block;
    width: 71px;
    height: 70px;
    background-image: $icon;
}

@mixin socialMedia($width,$height,$icon){
    content: "";
    display: block;
    width: $width;
    height: $height;
    background-image: $icon;
}

