.dynamic {
    padding: 120px 0 80px 0;
    position: relative;

    .dynamic__link a {
        margin-bottom: $margin-5;
        font-family: $fontPrimary;
        color: $white;
        background: $secondary;
        border: 1.5px solid $secondary;
        border-radius: 2px;
        padding: 11px 22px;
        font-style: normal;
        font-weight: $weight-3;
        font-size: $size-2-custom;
        line-height: 150%;
        align-items: center;
        display: flex;
        width: fit-content;
        margin: 100px auto 0;
        transition: background .3s ease-in-out;

        &:hover {
            background: transparent;
            transition: background .3s ease-in-out;
        }
    }

    .dynamic__container {
        margin-bottom: $margin-6;
        .title {
            font-family: $fontPrimary;
            font-style: normal;
            font-weight: $weight-3;
            font-size: $size-4-custom;
            line-height: 105%;
            text-align: center;
            color: $white;
            margin-bottom: $margin-3;
            margin-top: 0;

            @media (max-width: $tablet){
                font-size: $size-11;
            }
        }

        .content {
            font-family: $fontSecondary;
            font-style: normal;
            font-weight: $weight-1;
            font-size: $size-1-custom;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.05em;
            font-feature-settings: 'liga' off;
            color: $white;
            margin-top: 0;
            &:nth-of-type(1){
                margin-bottom: 5px;
            }
        }
    }

    .dynamic__hr-line {
        left: 24%;
        height: 4px;
        width: 44%;
        background: #B4AFA3;
        border-radius: 2px;
        margin: auto;
        display: flex;
        position: absolute;
        bottom: 18.3%;

        @media (max-width: $desktop-3) {
            left: 28%;
            width: 36%;
            bottom: 21%;
        }

        @media (max-width: $desktop-2) {
            left: 33%;
            width: 24%;
            bottom: 25.3%;
        }

        @media (max-width: $desktop-1) {
            left: 36%;
            width: 19%;
            bottom: 27.2%;
        }

        @media (max-width: $mobile) {
            left: 15%;
            width: 52%;
            bottom: 25.5%;
        }

        .progressBar {
            background-color: $secondary;
            height: 4px;
            border-radius: 2px;
        }
    }

    .dynamic__active {
        position: relative;

        .dynamic__infos {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
    
            width: 100%;
            height: auto;
            padding: 32px;
    
            z-index: 5;

            color: $white;
            @media (max-width: $tablet) {
                padding: 8px;
            }

            .dynamic__infos-header {
                display: flex;
                justify-content: space-between;

                h3 {
                    font-family: $fontPrimary;
                    font-size: $size-11;
                    font-weight: $weight-3;

                    margin: 0;
                    @media (max-width: $mobile) {
                        font-size: $size-7;
                    }
                }
            }

            .dynamic__infos-footer {
                display: none;
                
                li {
                    font-family: $fontSecondary;
                    font-size: $size-4;
                    line-height: 180%;
                    @media (max-width: $mobile) {
                        font-size: $size-2;
                    }
                }
            }
        }
    }

    .slick-slider  {
        .slick-slide {
            opacity: 0.2;

            padding: 0 16px;
            @media (max-width: $tablet) {
                padding: 0 4px;
            }

            img {
                border-radius: 2px;
                @media (max-width: $mobile) {
                    height: 280px;
                    object-fit: cover;
                }
            }
        }
    
        .slick-slide.slick-active {
            opacity: 1 !important;

            .dynamic__infos-footer {
                display: block;
            }
        }

        .slick-prev {
            left: unset;
            z-index: 1;
            top: unset;
            right: 530px;
            bottom: -35px;
            @media (max-width: $tablet) {
                right: 88px;
                bottom: -35px;
            }

            &::before {
                content: "";
                display: block;
                width: 13px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='13' height='20' viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3334 20L0.333374 10L10.3334 0L12.6667 2.33333L5.00004 10L12.6667 17.6667L10.3334 20Z' fill='%23AB9E56'/%3E%3C/svg%3E%0A");
            }
        }

        .slick-next {
            right: 466px;
            top: unset;
            bottom: -24px;
            @media (max-width: $tablet){
                right: 58px;
            }

            &::before {
                content: "";
                display: block;
                width: 40px;
                height: 40px;
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_143_82' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='40' height='40'%3E%3Crect width='40' height='40' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_143_82)'%3E%3Cpath d='M15.6667 30L13.3334 27.6667L21 20L13.3334 12.3333L15.6667 10L25.6667 20L15.6667 30Z' fill='%23AB9E56'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }
    }
}